<template>
  <v-app-bar clipped-right app dark color="#147BD1">
    <v-btn
      outlined
      dark
      class="rounded-lg"
      v-if="!directLoad && currentView !== 'noCollection'"
      @click="backToSearch()"
    >
      <v-icon class="mr-2">mdi-arrow-left</v-icon>
      Back to Search
    </v-btn>
    <v-spacer />
    <NavigationMenu current-system="case" :restricted="me.restricted" />
    <Notifications />
    <Loader :loading="isLoading" :errors="errors" @refresh="fetchData()" />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import authentication from "@/shared/authentication";
import fetchURLParam from "@/shared/url_params";
import setURLParam from "@/shared/set_url_params";
import Loader from "@/shared/Loader";
import NavigationMenu from "@/shared/NavigationMenu";
import Notifications from "@/shared/Notifications";

export default {
  name: "AppBar",
  components: {
    Loader,
    NavigationMenu,
    Notifications,
  },
  data() {
    return {
      fetching: false,
      intervalID: null,
      fetchUpdateMS: 30000,
      errors: [],
      preloadCaseUID: null,
    };
  },
  created() {
    this.fetchCredentials = authentication.fetchCredentials;
    this.fetchMe = authentication.fetchMe;
  },
  watch: {
    reloadTrigger(value) {
      if (value) {
        this.fetchData();
        this.reloadComplete();
      }
    },
    caseUID(value) {
      setURLParam("cuid", value);
    },
    collectionUID(value) {
      setURLParam("uid", value);
    },
    currentView(value) {
      setURLParam("view", value);
    },
    messageRefetchTrigger(value) {
      if (value) {
        this.refetchMessages();
      }
    },
  },
  computed: {
    ...mapGetters([
      "collectionUID",
      "caseUID",
      "directLoad",
      "isLoading",
      "isEditing",
      "currentView",
      "reloadTrigger",
      "collection",
      "progressModal",
      "redoModal",
      "outsourceModal",
      "me",
      "credentials",
      "messageRefetchTrigger"
    ]),
  },
  methods: {
    ...mapActions([
      "setCollectionUID",
      "setCaseUID",
      "setDirectLoad",
      "setSearchKeyword",
      "setDepartment",
      "startLoading",
      "stopLoading",
      "startBlockLoading",
      "stopBlockLoading",
      "setCurrentView",
      "reloadComplete",
      "setMe",
      "setStatuses",
      "setEmployees",
      "setBranches",
      "setCollection",
      "setMessageTypes",
      "setMessages",
      "setApprovalTypes",
      "setOrderForms",
      "setMaterials",
      "setMaterialTypes",
      "setJobTypes",
      "setBillKits",
      "setAttachments",
      "setAnalogs",
      "setGenericComponents",
      "setAudit",
      "setPartners",
      "setOrderStages",
      "setCommunicationLogs",
      "reloadMessageComplete",
    ]),
    fetchParams() {
      if (this.collectionUID) {
        return;
      }
      const collectionUIDParam = fetchURLParam("uid");
      const searchParam = fetchURLParam("search");
      const viewParam = fetchURLParam("view");
      const departmentParam = fetchURLParam("department");
      this.preloadCaseUID = fetchURLParam("cuid");
      if (collectionUIDParam) {
        this.setCollectionUID(collectionUIDParam);
        this.setDirectLoad();
        if (viewParam) {
          this.setCurrentView(viewParam);
        }
        if (departmentParam) {
          this.setDepartment(departmentParam);
        }
        return;
      }
      if (searchParam) {
        this.setSearchKeyword(searchParam);
      }
      this.setCurrentView("noCollection");
    },
    fetchData() {
      return new Promise((resolve) => {
        if (
          this.currentView === "active" ||
          this.progressModal ||
          this.redoModal ||
          this.outsourceModal ||
          this.isEditing
        ) {
          return resolve();
        }
        this.errors = [];
        this.fetchParams();
        if (!this.collectionUID) {
          this.stopLoading();
          return resolve();
        }
        if (this.fetching) {
          return resolve();
        }
        this.fetching = true;
        const previousCaseUID = this.caseUID;
        this.startLoading();
        const requests = [];
        requests.push(this.fetchStatuses());
        requests.push(this.fetchEmployees());
        requests.push(this.fetchBranches());
        requests.push(this.fetchCollection());
        requests.push(this.fetchMessageTypes());
        requests.push(this.fetchCommunicationLogs());
        requests.push(this.fetchApprovalTypes());
        requests.push(this.fetchOrderForms());
        requests.push(this.fetchMaterials());
        requests.push(this.fetchMaterialTypes());
        requests.push(this.fetchJobTypes());
        requests.push(this.fetchBillKits());
        requests.push(this.fetchAnalogs());
        requests.push(this.fetchAttachments());
        requests.push(this.fetchGenericComponents());
        requests.push(this.fetchAudit());
        requests.push(this.fetchPartners());
        requests.push(this.fetchOrderStages());
        Promise.all(requests).then(() => {
          this.fetchMessages().then(() => {
            this.fetching = false;
            this.stopLoading();
            this.stopBlockLoading();
            if (this.preloadCaseUID) {
              this.setCaseUID(this.preloadCaseUID);
              this.preloadCaseUID = null;
            } else {
              this.setCaseUID(previousCaseUID);
            }
            resolve();
          });
        });
      });
    },
    backToSearch() {
      window.document.title = "Case Manager";
      this.setCollectionUID(null);
      this.setCurrentView("noCollection");
    },
    async fetchStatuses() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/statuses/")
          .then((response) => {
            this.setStatuses(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Statuses failed to load");
            resolve();
          });
      });
    },
    async fetchEmployees() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/employees/")
          .then((response) => {
            this.setEmployees(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Employees failed to load");
            resolve();
          });
      });
    },
    async fetchBranches() {
      return new Promise((resolve) => {
        this.$axios.get("/api/v2/case/branches/").then((response) => {
          this.setBranches(response.data);
          resolve();
        });
      });
    },
    async fetchCollection() {
      return new Promise((resolve) => {
        this.$axios
          .get(`/api/v2/collection/${this.collectionUID}/`)
          .then((response) => {
            window.document.title = `${response.data.collection_id} - Case Manager`;
            this.setCollection(response.data);
            resolve();
          })
          .catch(() => {
            this.setCurrentView("noCollection");
            this.errors.push("Could not load case collection");
            resolve();
          });
      });
    },
    async fetchMessageTypes() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/messages/types/")
          .then((response) => {
            this.setMessageTypes(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Message Types failed to load");
            resolve();
          });
      });
    },
    async fetchMessages() {
      return new Promise((resolve) => {
        this.$axios
          .get(`/api/v2/collection/${this.collectionUID}/messages/?extra-ref=${this.collection.shipping_client.uid}`)
          .then((response) => {
            this.setMessages(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Messages failed to load");
            resolve();
          });
      });
    },
    async fetchCommunicationLogs() {
      return new Promise((resolve) => {
        this.$axios
          .get(`/api/v2/collection/${this.collectionUID}/communicationlogs/`)
          .then((response) => {
            this.setCommunicationLogs(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Could not load communication logs");
            resolve();
          });
      });
    },
    async fetchApprovalTypes() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/approvals/types/")
          .then((response) => {
            this.setApprovalTypes(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Approval Types failed to load");
            resolve();
          });
      });
    },
    async fetchOrderForms() {
      return new Promise((resolve) => {
        this.$axios
          .post(`/api/v2/collection/${this.collectionUID}/`, {
            action: "order_form_list",
          })
          .then((response) => {
            this.setOrderForms(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Order forms failed to load");
            resolve();
          });
      });
    },
    async fetchMaterials() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/materials/")
          .then((response) => {
            this.setMaterials(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Materials failed to load");
            resolve();
          });
      });
    },
    async fetchMaterialTypes() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/material_types/")
          .then((response) => {
            this.setMaterialTypes(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Material Types failed to load");
            resolve();
          });
      });
    },
    async fetchJobTypes() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/job_types/")
          .then((response) => {
            this.setJobTypes(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Job types failed to load");
            resolve();
          });
      });
    },
    async fetchBillKits() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/kit_list/")
          .then((response) => {
            this.setBillKits(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Kits failed to load");
            resolve();
          });
      });
    },
    async fetchAttachments() {
      return new Promise((resolve) => {
        this.$axios
          .post(`/api/v2/collection/${this.collectionUID}/`, {
            action: "attachment_list",
          })
          .then((response) => {
            this.setAttachments(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Attachments failed to load");
            resolve();
          });
      });
    },
    async fetchAnalogs() {
      return new Promise((resolve) => {
        this.$axios
          .post(`/api/v2/collection/${this.collectionUID}/`, {
            action: "analog_list",
          })
          .then((response) => {
            this.setAnalogs(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Analogs failed to load");
            resolve();
          });
      });
    },
    async fetchGenericComponents() {
      return new Promise((resolve) => {
        this.$axios
          .post(`/api/v2/collection/${this.collectionUID}/`, {
            action: "generic_component_list",
          })
          .then((response) => {
            this.setGenericComponents(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Generic Components failed to load");
            resolve();
          });
      });
    },
    async fetchAudit() {
      return new Promise((resolve) => {
        this.$axios
          .post(`/api/v2/collection/${this.collectionUID}/`, {
            action: "audit_list",
          })
          .then((response) => {
            this.setAudit(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Audit failed to load");
            resolve();
          });
      });
    },
    async fetchPartners() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/case/partners/")
          .then((response) => {
            this.setPartners(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Partners failed to load");
            resolve();
          });
      });
    },
    async fetchOrderStages() {
      return new Promise((resolve) => {
        this.$axios
          .get("/api/v2/collections/order_stages/")
          .then((response) => {
            this.setOrderStages(response.data);
            resolve();
          })
          .catch(() => {
            this.errors.push("Order stages failed to load");
            resolve();
          });
      });
    },
    async refetchMessages() {
      return new Promise((resolve) => {
        this.fetchCollection().then(() => {
          this.fetchMessages();
          this.reloadMessageComplete();
          resolve();
        });
      });
    },
  },
  mounted() {
    this.startBlockLoading();
    this.fetchCredentials().then(() => {
      this.fetchMe().then(() => {
        this.fetchData().then(() => {
          this.stopBlockLoading();
        });
      });
    });
    this.intervalID = setInterval(this.fetchData, this.fetchUpdateMS);
  },
  beforeDestroy() {
    if (!this.intervalID) {
      return;
    }
    clearInterval(this.intervalID);
  },
};
</script>
