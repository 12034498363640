<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    right
    temporary
    absolute
    width="90%"
    style="z-index: 99999;"
  >
    <v-container fluid>
      <ComponentHeader v-model="tab" />
      <AlertingMessageAlerts v-if="tab === 'overview'" />
      <Toolbar class="mb-5" v-if="tab === 'overview'" />
      <Overview v-if="tab === 'overview'" />
      <Notes v-if="tab === 'notes'" />
      <Checklist v-if="tab === 'checklist'" />
      <Timeline v-if="tab === 'timeline'" />
      <Approval v-if="tab === 'approval'" />
      <Outsource v-if="tab === 'outsourcing'" />
      <Form v-if="tab === 'forms'" />
      <Files v-if="tab === 'files'" />
      <Audit v-if="tab === 'audit'" />
      <v-row>
        <v-col sm="12" class="text-center mt-10" v-if="caseUID">
          <v-divider />
          <small>
            Case UID:
            <code>{{ caseUID }}</code>
          </small>
        </v-col>
      </v-row>
      <ProgressModal />
      <CompleteModal />
      <RedoModal />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AlertingMessageAlerts from "@/collection/components/shared/AlertingMessageAlerts";
import ComponentHeader from "./ComponentDrawer/ComponentHeader";
import Toolbar from "./ComponentDrawer/Toolbar";
import Overview from "./ComponentDrawer/Overview";
import Notes from "./ComponentDrawer/Notes";
import Checklist from "./ComponentDrawer/Checklist";
import Timeline from "./ComponentDrawer/Timeline";
import Approval from "./ComponentDrawer/Approval";
import Outsource from "./ComponentDrawer/Outsource";
import Form from "./ComponentDrawer/Form";
import Files from "./ComponentDrawer/Files";
import Audit from "./ComponentDrawer/Audit";
import ProgressModal from "./ComponentDrawer/ProgressModal";
import CompleteModal from "./ComponentDrawer/CompleteModal";
import RedoModal from "./ComponentDrawer/RedoModal";

export default {
  name: "ComponentDrawer",
  components: {
    ComponentHeader,
    Toolbar,
    AlertingMessageAlerts,
    Overview,
    Notes,
    Checklist,
    Timeline,
    Approval,
    Outsource,
    Form,
    Files,
    Audit,
    ProgressModal,
    CompleteModal,
    RedoModal,
  },
  watch: {
    caseUID(value) {
      if (value) {
        return;
      }
      this.tab = "overview";
    },
  },
  computed: {
    ...mapGetters(["caseUID", "collection", "kase", "caseTab"]),
    tab: {
      get() {
        return this.caseTab;
      },
      set(value) {
        this.setCaseTab(value);
      },
    },
    drawer: {
      get() {
        return !!this.caseUID;
      },
      set(value) {
        if (value) {
          return;
        }
        this.setCaseUID(null);
      },
    },
    hasGroup() {
      if (!this.kase.group_number) {
        return false;
      }
      if (
        this.collection.cases &&
        this.collection.cases.filter((kase) => {
          return (
            kase.group_number === this.kase.group_number &&
            kase.uid !== this.kase.uid
          );
        }).length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["setCaseUID", "setCaseTab"]),
  },
};
</script>
