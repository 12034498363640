<template>
  <v-dialog v-model="identificationModal" max-width="500">
    <v-card class="rounded-lg">
      <v-toolbar dark>
        <v-btn icon dark @click="identificationModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Identification</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-tabs v-model="identificationTypeTab">
          <v-tab>
            <v-icon class="mr-2">mdi-email</v-icon>
            Email Address
          </v-tab>
          <v-tab>
            <v-icon class="mr-2">mdi-cellphone</v-icon>
            Mobile Number
          </v-tab>
        </v-tabs>
        <v-card v-if="identificationTypeTab === 0" class="my-5" elevation="0">
          <v-text-field
            v-model="identification"
            outlined
            dense
            class="rounded-lg"
            placeholder="Email Address"
            type="email"
          />
        </v-card>
        <v-card
          v-if="identificationTypeTab === 1"
          class="my-5"
          min-height="200"
          elevation="0"
        >
          <VuePhoneNumberInput
            v-model="identification"
            border-radius="8"
            default-country-code="AU"
            :preferred-countries="['AU', 'US']"
            @update="updateMobile"
          />
        </v-card>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" class="rounded-lg" @click="save()">
          <v-icon class="mr-2">mdi-content-save</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "IdentificationModal",
  components: {
    VuePhoneNumberInput
  },
  props: ["value"],
  data() {
    return {
      identification: null,
      identificationTypeTab: 0,
      phoneResults: null
    };
  },
  watch: {
    identificationTypeTab() {
      this.identification = null;
    }
  },
  computed: {
    identificationModal: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.identificationTypeTab = 0;
          this.identification = null;
        }
        this.$emit("input", value);
      }
    }
  },
  methods: {
    updateMobile(payload) {
      this.phoneResults = payload;
    },
    save() {
      let identification = null;
      if (this.identificationTypeTab === 0) {
        if (!this.identification || this.identification.length === 0) {
          return;
        }
        if (
          !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(
            this.identification
          )
        ) {
          return;
        }
        identification = this.identification;
      } else if (this.identificationTypeTab === 1) {
        if (!this.phoneResults) {
          return;
        }
        if (!this.phoneResults.isValid) {
          return;
        }
        identification = this.phoneResults.e164;
      }
      this.$emit("save", identification);
      this.identificationModal = false;
    }
  }
};
</script>
