<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :x-small="!!small"
          icon
          fab
          v-bind="attrs"
          v-on="on"
          @click="openFolder"
        >
          <v-icon>mdi-folder</v-icon>
        </v-btn>
      </template>
      Open {{ openPrompt }} Folder
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :x-small="!!small"
          icon
          fab
          v-bind="attrs"
          v-on="on"
          @click="copyPath"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      Copy {{ openPrompt }} Path
    </v-tooltip>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :x-small="!!small" icon fab v-bind="attrs" v-on="on">
          <v-icon>mdi-tools</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="enableExportModal"
          @click="exportAbutmentModal = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-play</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Open Export Dialog</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="600" v-model="exportAbutmentModal" persistent>
      <ExportAbutmentModal
        v-if="exportAbutmentModal"
        @close="exportAbutmentModal = false"
        :kase="kase"
      />
    </v-dialog>
  </div>
</template>

<script>
import startApp from "./oep_helper";
import ExportAbutmentModal from "./OEPHelper/ExportAbutmentModal.vue";

export default {
  name: "OEPHelper",

  props: ["collection", "kase", "small"],

  components: { ExportAbutmentModal },

  data() {
    return {
      exportAbutmentModal: false,
    };
  },

  computed: {
    openPrompt() {
      if (this.kase) {
        return "Case";
      }
      return "Collection";
    },
    folderPath() {
      if (this.kase) {
        if (
          this.collection.new_folder_structure &&
          this.kase &&
          this.kase.folder_path
        ) {
          return `\\\\corp.osteonmedical.com\\cases\\${this.kase.folder_path}\\`;
        } else if (
          !this.collection.new_folder_structure &&
          this.kase &&
          this.kase.folder_path
        ) {
          return `\\\\corp.osteonmedical.com\\files\\Current Cases\\${this.kase.folder_path}\\`;
        }
      } else if (this.collection) {
        if (
          this.collection.new_folder_structure &&
          this.collection.folder_path
        ) {
          return `\\\\corp.osteonmedical.com\\cases\\${this.collection.folder_path}\\`;
        }
      }
      return null;
    },

    enableExportModal() {
      if (this.kase && this.kase.automation_results) {
        return (
          this.kase.automation_results.filter(
            (r) => r.type === "nexus_connect" && r.payload.nc_session_id
          ).length > 0
        );
      }
      return false;
    },
  },

  methods: {
    openFolder() {
      if (!this.folderPath) {
        alert("Cannot open case folder for this case.");
        return;
      }
      startApp(`explorer:${this.folderPath}`);
      this.$emit("installPopup");
    },
    copyPath() {
      if (!this.folderPath) {
        alert("Cannot copy path for this case.");
        return;
      }
      navigator.clipboard.writeText(this.folderPath);
    },
    startAutoMA() {
      if (!this.folderPath) {
        alert("Cannot start Auto MA for this case.");
        return;
      }
      startApp(`osteonLauncher:${this.folderPath}`);
    },
  },
};
</script>
