<template>
  <v-card class="rounded-lg">
    <v-toolbar dark>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Export Abutment Postitions</v-toolbar-title>
    </v-toolbar>
    <v-card class="rounded-20" flat>
      <v-card-title class="title-font"> SCAN BODY </v-card-title>
      <v-row class="ma-2">
        <v-col align="center">
          <v-select
            v-model="scanBodyComponentId"
            label="Scan Body Library for Export"
            :items="mappedScanBodies"
            outlined
            dense
            hide-details
            class="mx-4"
            :menu-props="{ rounded: '10' }"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="rounded-20" flat>
      <v-card-title class="title-font"> HEALING CAP </v-card-title>
      <v-row class="ma-2">
        <v-col class="ml-6 d-flex align-center">
          <v-switch
            inset
            v-model="enableHealingCap"
            hide-details
            class="mt-0 py-2"
            label="Enable"
          />
          <v-select
            v-show="enableHealingCap"
            v-model="healingCapComponentId"
            :disabled="!enableHealingCap"
            label="Healing Cap Library for Export"
            :items="mappedHealingCaps"
            outlined
            dense
            hide-details
            class="mx-4"
            :menu-props="{ rounded: '10' }"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="rounded-lg"
        color="success"
        :disabled="!downloadEnabled || loading"
        @click="exportAbutment"
        :loading="loading"
      >
        <v-icon class="mr-2">mdi-export</v-icon>
        EXPORT
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const Axs = require("axios");
const axsExpots = Axs.create();
const exportUrl = "/proxy/nexus_connect/exports/";

export default {
  name: "ExportAbutmentModal",

  props: ["kase", "nc_session_id", "value"],

  data() {
    return {
      loading: false,
      components: [],
      enableHealingCap: false,
      scanBodyComponentId: null,
      healingCapComponentId: null,
    };
  },

  computed: {
    mappedScanBodies() {
      if (!this.components) return [];
      return this.components
        .filter((c) => c.component_type === "Scan Body")
        .map((c) => {
          return {
            text: c.name,
            value: c.id,
          };
        });
    },

    mappedHealingCaps() {
      if (!this.components) return [];
      return this.components
        .filter((c) => c.component_type === "Healing Cap")
        .map((c) => {
          return {
            text: c.name,
            value: c.id,
          };
        });
    },

    downloadEnabled() {
      if (this.enableHealingCap)
        return this.scanBodyComponentId && this.healingCapComponentId;
      else return !!this.scanBodyComponentId;
    },

    ncSessionId() {
      return this.kase.automation_results.filter(
        (r) => r.type === "nexus_connect"
      )[0].payload.nc_session_id;
    },
  },

  methods: {
    async fetchComponents() {
      this.loading = true;
      await this.$axios
        .get("/api/v2/management/components/")
        .then((response) => {
          this.components = response.data;
          this.loading = false;
        });
    },

    async exportAbutment() {
      this.loading = true;
      const payload = {
        nc_session_id: this.ncSessionId,
        scan_body_id: this.scanBodyComponentId,
      };
      if (this.healingCapComponentId)
        payload.healing_cap_id = this.healingCapComponentId;

      const exportsResponse = await axsExpots.post(exportUrl, payload, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([exportsResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "exports.zip");
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
      this.loading = false;
    },
  },

  mounted() {
    this.fetchComponents();
  },
};
</script>
